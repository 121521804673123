import { from } from "rxjs";
import { pluck, tap, map } from "rxjs/operators";
import axios from "axios";
import { fraudUrl } from "./fraudConfig";

export function fraudFetchAPI(url, axiosOptions) {
  const baseURL = fraudUrl;
  const axiosInstance = axios.create({
    baseURL,
    timeout: 20000
  });
  const options = { ...axiosOptions, ...{ method: "post" }, ...{ url } };

  return from(axiosInstance.request(options)).pipe(
    tap(response => {
      cache[options.url] = {
        lastPulled: Date.now(),
        value: response
      };
      if (response.results && Array.isArray(response.results)) {
        response.results.forEach(item => {
          if (item.url) {
            cache[item.url] = {
              lastPulled: Date.now(),
              value: item
            };
          }
        });
      }
    })
  );
}
const cache = {};
