import { from, observable } from "rxjs";
import { pluck, tap, map } from "rxjs/operators";
import { fraudFetchAPI } from "./fraudFetchAPI";
import { navigateToUrl } from "single-spa";

let currentUser = {};
function fetchFraudAPIWithPermission(url, axiosOptions) {
  if (Object.keys(currentUser).length === 0) {
    let currentUserString = sessionStorage.getItem("CurrentUser");
    if (currentUserString != null) {
      currentUser = JSON.parse(currentUserString);
    } else {
      navigateToUrl("/login");
      // need to return something to avoid error
      // return "123";
    }
  }

  let axiosOptionsWithPermission = axiosOptions || {};
  axiosOptionsWithPermission.headers = {
    Authorization: "Bearer " + sessionStorage.getItem("jwt")
  };
  //console.log(axiosOptionsWithPermission)
  return fraudFetchAPI(url, axiosOptionsWithPermission);
}

export { fetchFraudAPIWithPermission };
